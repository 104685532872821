.c-LoggedIn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 860px;

  &__message {
    font-size: 1.85em;
    display: block;

    &:not(:first-of-type) {
      margin-top: 45px;
    }
  }

  &__emphasizedText {
    font-weight: 500;
  }

  &__actionArea {
    margin-top: 35px;
  }

  &__loginButton {
    padding: 12px 40px;
    text-align: center;
    display: inline-block;
    border-radius: 30px;
    text-transform: uppercase;
    color: #fff;
    background-color: #447c58;
    cursor: pointer;
    font-weight: 500;
    transition: 0.4s ease-in-out;

    &:hover {
      background-color: #386648;
    }
  }
}
