* {
  /* -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  perspective: 1000; */
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #444;
  overflow-x: hidden;
  font-weight: 300;
  font-size: 15px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.natural-color-custom,
.natural-color-custom:hover {
  color: #444 !important;
  background-color: #ddd !important;
}

.warning-color-custom,
.warning-color-custom:hover {
  background-color: salmon !important;
}

.success-color-custom,
.success-color-custom:hover {
  background-color: #88d453 !important;
}

.MuiAutocomplete-popup {
  z-index: 999999 !important;
}

.MuiDropzoneArea-root {
  margin-bottom: 20px;
  min-height: 210px !important;
}

.MuiDialogContent-root {
  overflow-x: hidden !important;
}

.MuiDropzonePreviewList-root {
  justify-content: center !important;
}

.MuiDropzonePreviewList-imageContainer {
  max-width: 60% !important;
  flex-basis: 60% !important;
}

.MuiAlert-root {
  position: relative !important;
  padding: 2px 11px !important;
}

.MuiAlert-icon {
  margin-right: 8px !important;
}

.MuiSnackbar-root .error-list .single-list:before {
  content: "* ";
}

.MuiInputAdornment-root {
  position: relative !important;
  right: 4px !important;
}

.MuiInputAdornment-root .MuiIconButton-root {
  padding: 3px !important;
  color: #888 !important;
}

.MuiFormHelperText-contained {
  margin-left: 0 !important;
  margin-bottom: -3px !important;
  margin-top: 3px !important;
  font-style: italic;
  line-height: 1.325 !important;
}

.form-group.disabled .MuiInputBase-input {
  background-color: #f1f1f1;
  color: #888;
}

.form-group.disabled .MuiFormControl-root {
  pointer-events: none;
}

.inner-half-form.disabled .MuiInputBase-input {
  background-color: #f1f1f1;
  color: #888;
}

.inner-half-form.disabled .MuiFormControl-root {
  pointer-events: none;
}

.inner-triple-form.disabled .MuiInputBase-input {
  background-color: #f1f1f1;
  color: #888;
}

.inner-triple-form.disabled .MuiFormControl-root {
  pointer-events: none;
}

.form-group.pending .MuiInputBase-input,
.inner-half-form.pending .MuiInputBase-input {
  color: #ff9a16;
}

.form-group.accepted .MuiInputBase-input,
.inner-half-form.accepted .MuiInputBase-input {
  color: #13b26d;
}

.form-group.rejected .MuiInputBase-input,
.inner-half-form.rejected .MuiInputBase-input {
  color: #fc5a5a;
}

/* .fixed-hardware-acceleration {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  perspective: 1000;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
} */

.full-screen-app-bar .MuiAppBar-root {
  box-shadow: none !important;
}

.full-screen-app-bar .MuiDialog-paper {
  overflow-y: initial !important;
}

.fullscreen-pdf-file-viewer {
  position: relative;
  top: 64px;
  height: calc(100% - 64px);
  box-sizing: border-box;
  overflow: hidden;
}

.fullscreen-pdf-file-viewer .pdf-file-viewer {
  width: 100%;
  height: 100%;
}

.fullscreen-image-file-viewer {
  position: relative;
  top: 64px;
  height: calc(100% - 64px);
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.fullscreen-image-file-viewer .image-file {
  max-height: 100%;
  object-fit: cover;
}

.fullscreen-modal-form-body {
  position: relative;
  top: 64px;
  height: calc(100% - 64px);
  box-sizing: border-box;
  padding: 25px 20px;
  overflow-y: auto;
  overflow-x: hidden;
}

.fullscreen-modal-form-body .MuiDialogContent-root {
  padding-top: 0;
}

.fullscreen-modal-form-body .MuiDialogContentText-root {
  margin-bottom: 23px;
}

.fullscreen-modal-form-body .form-group {
  margin-bottom: 20px;
  position: relative;
}

.fullscreen-modal-form-body .form-group:last-of-type {
  margin-bottom: 0;
}

.fullscreen-modal-form-body .form-group label {
  color: #444 !important;
}

.fullscreen-modal-form-body
  .form-group
  .MuiFormControl-root
  .MuiOutlinedInput-root:hover
  fieldset {
  border: 1px solid #888 !important;
}

.fullscreen-modal-form-body
  .form-group
  .MuiFormControl-root
  .MuiOutlinedInput-root.Mui-focused
  fieldset {
  border: 1px solid #5773d6 !important;
}

.fullscreen-modal-form-body .form-group.required label:after {
  content: "*";
  color: #e74747;
  margin-left: 2px;
}

.fullscreen-modal-form-body .form-group .MuiAutocomplete-root,
.fullscreen-modal-form-body .form-group .MuiFormControl-root {
  background-color: #fff;
  width: 100%;
}

.fullscreen-modal-form-body .form-group [class*="MuiOutlinedInput-root"] {
  padding: 2px !important;
}

.fullscreen-modal-form-body .form-group .MuiInputLabel-formControl {
  top: -6px !important;
}

.fullscreen-modal-form-body .form-group .MuiOutlinedInput-input {
  padding: 10px 8px !important;
}

.fullscreen-modal-form-body
  .form-group
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -1px) scale(0.75) !important;
}

.main-title-modal-form {
  margin-left: 10px !important;
}

.single-button-right-top-custom {
  position: absolute !important;
  right: 20px !important;
  background-color: #fff !important;
  width: 110px;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.4s ease-in-out !important;
}

.single-button-right-top-custom:hover {
  background-color: #f7f7f7 !important;
}

.inner-form-group-wrapper {
  display: flex;
}

.inner-form-group-wrapper .inner-half-form {
  width: 50%;
}

.inner-form-group-wrapper .inner-half-form:first-of-type {
  padding-right: 5px;
}

.inner-form-group-wrapper .inner-half-form:last-of-type {
  padding-left: 5px;
}

.inner-form-group-wrapper .inner-triple-form {
  width: 33.33333%;
}

.inner-form-group-wrapper .inner-triple-form:first-of-type {
  padding-right: 5px;
}

.inner-form-group-wrapper .inner-triple-form:nth-child(2) {
  padding-left: 5px;
  padding-right: 5px;
}

.inner-form-group-wrapper .inner-triple-form:last-of-type {
  padding-left: 5px;
}

.single-form-group-wrapper {
  margin-bottom: 50px;
}

.loading-circle {
  color: inherit !important;
}

.textbox-yellow-smooth,
.textbox-red-smooth,
.textbox-green-smooth,
.textbox-grey-smooth,
.textbox-blue-smooth,
.textbox-brown-smooth {
  display: inline-block;
  width: 100px;
  text-align: center;
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 0.95em;
  border: 1px solid #ccc;
  transition: 0.3s ease-in-out;
  font-weight: 300;
}

.textbox-brown-smooth {
  background-color: #ffe8db;
  color: #ed7c3b;
}

.textbox-blue-smooth {
  background-color: #e6f3fe;
  color: #108b93;
}

.textbox-yellow-smooth {
  background-color: #fff5e7;
  color: #d2913c;
}

.textbox-red-smooth {
  background-color: #ffe4e3;
  color: #e6372e;
}

.textbox-green-smooth {
  background-color: #e9f8e9;
  color: #41a93f;
}

.textbox-grey-smooth {
  background-color: #f4f4f4;
  color: #aaa;
}

.upload-area .clear-icon {
  font-size: 1.5em;
}
