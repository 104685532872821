.menu-pemilihan__container {
    margin: 0 auto 0;
    max-width: 1080px;
}
.menu-pemilihan__container p.verified-message {
    text-align: center;
    color: #27d352;
    font-weight: 400;
}
.menu-pemilihan__container .surat-suara__container {
    width: 1080px;
}
@media only screen and (max-width: 1000px) {
    .menu-pemilihan__container .surat-suara__container {
        width: 100%;
   }
}
.menu-pemilihan__container .surat-suara__container .surat-suara-title {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin: 50px auto 50px;
}
.menu-pemilihan__container .surat-suara__container .surat-suara__flex {
    display: flex;
    justify-content: space-between;
    margin: 0 auto 0;
}
.menu-pemilihan__container .surat-suara__container .surat-suara__flex .paslon-card {
    transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    margin: 0 auto 25px;
    cursor: pointer;
    padding: 10px 0 10px;
    width: 450px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
}
@media only screen and (max-width: 1000px) {
    .menu-pemilihan__container .surat-suara__container .surat-suara__flex .paslon-card {
        width: 400px;
   }
}
.menu-pemilihan__container .surat-suara__container .surat-suara__flex .paslon-card:hover {
    box-shadow: 0px 0px 25px 0px rgba(16, 201, 78, 0.521);
}
.menu-pemilihan__container .surat-suara__container .surat-suara__flex .paslon-card .logos {
    margin: 15px 30px 15px;
}
.menu-pemilihan__container .surat-suara__container .surat-suara__flex .paslon-card .logos img {
    width: 100px;
}
.menu-pemilihan__container .surat-suara__container .surat-suara__flex .paslon-card .nomor-surat-suara {
    text-align: center;
}
.menu-pemilihan__container .surat-suara__container .surat-suara__flex .paslon-card img.paslon-img {
    text-align: center;
    margin: 0 auto 0;
    width: 100%;
}
.menu-pemilihan__container .surat-suara__container .surat-suara__flex .paslon-card .blank-image {
    height: 450px;
    width: 450px;
    margin: 0 auto 0;
}
.menu-pemilihan__container .surat-suara__container .surat-suara__flex .paslon-card .paslon-description {
    margin: 10px 0 10px;
    display: flex;
}
.menu-pemilihan__container .surat-suara__container .surat-suara__flex .paslon-card .paslon-description span {
    text-align: center;
    width: 240px;
    font-weight: 500;
}
.menu-pemilihan__container .surat-suara__container .surat-suara__flex .paslon-card .paslon-description span.single {
    text-align: center;
    margin: 0 auto 0;
}
.menu-pemilihan__container .surat-suara__container .surat-suara__flex .paslon-card .ttd-kartu {
    margin: 20px 50px 10px;
    text-align: right;
    font-size: 22px;
}
.menu-pemilihan__container .surat-suara__container .surat-suara__flex .paslon-card .ttd-kartu img {
    max-width: 100%;
}
.menu-pemilihan__container .surat-suara__container .surat-suara__flex .paslon-card:after {
    bottom: 0;
    background: linear-gradient(to bottom, #333 0%, transparent 100%);
    content: "";
    position: absolute;
    display: block;
    left: -10px;
    width: 1px;
    height: 50%;
}
.menu-pemilihan__container .surat-suara__container .surat-suara__flex .chosen-card {
    box-shadow: 0px 0px 0px 5px #28df74;
}
.menu-pemilihan__container .surat-suara__container .surat-suara__flex .chosen-card:hover {
    box-shadow: 0px 0px 0px 5px #28df74;
}
@media only screen and (max-width: 1000px) {
    .menu-pemilihan__container .surat-suara__container .surat-suara__flex {
        flex-direction: column;
        width: 100%;
   }
}
.menu-pemilihan__container .surat-suara__container .informasi-pilihan {
    width: 340px;
    text-align: center;
    margin: 20px auto 20px;
}
