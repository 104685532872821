.table-component__container table {
  width: 100%;
  table-layout: fixed;
}

.table-component__container .tbl-header {
  margin-top: 10px;
  background-color: #1d1e30;
  color: #f0f0f0;
}

.table-component__container .tbl-content {
  height: 1000px;
  overflow-x: auto;
  margin-top: 0px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.table-component__container th {
  padding: 20px 15px;
  text-align: left;
  font-weight: 500;
  font-size: 15px;
  text-transform: uppercase;
}

.table-component__container td {
  padding: 15px;
  text-align: left;
  vertical-align: middle;
  font-weight: 300;
  font-size: 15px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}

.table-component__container tr {
  text-align: center;
}

.table-component__container ::-webkit-scrollbar {
  width: 15px;
}

.table-component__container ::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

.table-component__container ::-webkit-scrollbar-thumb {
  background-color: #b1b1b1;
}

.tombol-buka-surat-suara {
  cursor: pointer;
  border-radius: 5px;
  margin: auto;
  background-color: rgb(238, 238, 238);
}
