.pemira-admin__container {
    margin: 0;
    width: 100%;
    height: 100%;
}
.pemira-admin__container h1 {
    padding-top: 60px;
    margin: 0;
    text-align: center;
}
.pemira-admin__container input {
    transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    background-color: #f7f7f7;
    font-size: 18px;
    line-height: 28px;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.0225em;
    position: relative;
    border-radius: 5px;
    font-weight: 200;
    border: 0;
    width: 300px;
    outline: none;
    margin-bottom: 7px;
    padding: 15px;
}
.pemira-admin__container input:focus {
    background-color: #ebebeb;
}
.pemira-admin__container input.small {
    padding: 5px 13px 5px;
    width: 200px;
}
.pemira-admin__container .login__container {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto 0;
    align-items: center;
    width: 300px;
}
.pemira-admin__container .login__container button {
    color: #fff;
    border: 0;
    outline: none;
    background-color: #5773d6;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.4s ease-in-out;
    display: inline-block;
    text-align: center;
    width: 330px;
    height: 37px;
    text-transform: uppercase;
}
.pemira-admin__container .pemira-dashboard__container {
    width: 100%;
}
.pemira-admin__container .pemira-dashboard__container .keterangan {
    font-size: 12px;
    width: 300px;
    margin: 10px 3vw 0;
}
.pemira-admin__container .pemira-dashboard__container .table-component__container {
   /* for custom scrollbar for webkit browser*/
}
.pemira-admin__container .pemira-dashboard__container .table-component__container .searchbox {
    margin: 10px 3vw 0;
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.pemira-admin__container .pemira-dashboard__container .table-component__container .searchbox .tracker-buttons {
    display: flex;
}
.pemira-admin__container .pemira-dashboard__container .table-component__container .searchbox .query-tracker {
    transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    cursor: pointer;
    height: 28px;
    width: fit-content;
    padding: 5px 13px 5px;
    border-radius: 5px;
    margin-left: 5px;
}
.pemira-admin__container .pemira-dashboard__container .table-component__container .searchbox .query-tracker:hover {
    background-color: #313131 3b;
}
.pemira-admin__container .pemira-dashboard__container .table-component__container .searchbox .active {
    background-color: #696969 44;
    font-weight: 500;
}
.pemira-admin__container .pemira-dashboard__container .table-component__container .searchbox .active:hover {
    background-color: #444 28;
}
.pemira-admin__container .pemira-dashboard__container .table-component__container h1 {
    font-size: 30px;
    color: #000;
    text-transform: uppercase;
    font-weight: 300;
    text-align: center;
    margin-bottom: 15px;
}
.pemira-admin__container .pemira-dashboard__container .table-component__container table {
    width: 100%;
    table-layout: fixed;
}
.pemira-admin__container .pemira-dashboard__container .table-component__container .tbl-header {
    margin-top: 10px;
    background-color: #1d1e30;
    color: #f0f0f0;
}
.pemira-admin__container .pemira-dashboard__container .table-component__container .tbl-content {
    height: 1000px;
    overflow-x: auto;
    margin-top: 0px;
    border: 1px solid rgba(255, 255, 255, 0.3);
}
.pemira-admin__container .pemira-dashboard__container .table-component__container th {
    padding: 20px 15px;
    text-align: left;
    font-weight: 500;
    font-size: 15px;
    text-transform: uppercase;
}
.pemira-admin__container .pemira-dashboard__container .table-component__container td {
    padding: 15px;
    text-align: left;
    vertical-align: middle;
    font-weight: 300;
    font-size: 15px;
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}
.pemira-admin__container .pemira-dashboard__container .table-component__container ::-webkit-scrollbar {
    width: 15px;
}
.pemira-admin__container .pemira-dashboard__container .table-component__container ::-webkit-scrollbar-track {
    background-color: #f0f0f0;
}
.pemira-admin__container .pemira-dashboard__container .table-component__container ::-webkit-scrollbar-thumb {
    background-color: #b1b1b1;
}
.dark-mode {
    color: #ebebeb;
    background-color: #1b1b1b;
}

/* SURAT SUARA */
