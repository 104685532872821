.navbar-pemira__container {
  width: 100%;
  margin: -10px 0 0;
  padding-top: 20px;
}
.navbar-pemira__container nav {
  top: 0;
  right: 0;
  left: 0;
  width: 319px;
  display: table;
  margin: 10px auto;
}
.navbar-pemira__container nav a {
  cursor: pointer;
  position: relative;
  width: fit-content;
  display: table-cell;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  padding: 10px 20px;
  transition: 0.2s ease color;
}
.navbar-pemira__container nav a:before, .navbar-pemira__container nav a:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.2s ease transform;
}
.navbar-pemira__container nav a:before {
  top: 0;
  left: 10px;
  width: 6px;
  height: 6px;
}
.navbar-pemira__container nav a:after {
  top: 5px;
  left: 18px;
  width: 4px;
  height: 4px;
}
.navbar-pemira__container nav a:nth-child(1):before {
  background-color: #c4c400;
}
.navbar-pemira__container nav a:nth-child(1):after {
  background-color: #ff7b00;
}
.navbar-pemira__container nav a:nth-child(2):before {
  background-color: #00e2ff;
}
.navbar-pemira__container nav a:nth-child(2):after {
  background-color: #89ff00;
}
.navbar-pemira__container nav a:nth-child(3):before {
  background-color: purple;
}
.navbar-pemira__container nav a:nth-child(3):after {
  background-color: palevioletred;
}
.navbar-pemira__container #indicator {
  position: absolute;
  left: 1px;
  top: 0;
  width: 100px;
  height: 3px;
  background-color: #fff;
  border-radius: 5px;
  transition: 0.2s ease left;
}
.navbar-pemira__container nav a:hover {
  color: #7c7c7c;
}
.navbar-pemira__container nav a:hover:before, .navbar-pemira__container nav a:hover:after {
  transform: scale(1);
}
.navbar-pemira__container nav a:nth-child(1):hover ~ #indicator {
  background: linear-gradient(130deg, yellow, red);
}
.navbar-pemira__container nav a:nth-child(2):hover ~ #indicator {
  left: 34%;
  background: linear-gradient(130deg, #00e2ff, #89ff00);
}
.navbar-pemira__container nav a:nth-child(3):hover ~ #indicator {
  left: 70%;
  background: linear-gradient(130deg, purple, palevioletred);
}
.navbar-pemira__container #ytd-url {
  display: block;
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 10px 14px;
  margin: 20px;
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  background-color: #000;
  border-radius: 4px;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.3);
  z-index: 125;
}
