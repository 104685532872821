.landing-page-wrapper {
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}

.landing-page .box-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.1);
  text-align: center;
  color: #447c58;
  max-width: 600px;
}

.landing-page .box-wrapper h3.brand {
  font-size: 4em;
  font-weight: 600;
  line-height: 1.55;
  margin-bottom: 5px;
}

.landing-page .box-wrapper .brand {
  margin-bottom: 30px;
}

.landing-page .box-wrapper .brand img {
  width: 360px;
}

.landing-page .box-wrapper .description {
  margin: 0 0 35px;
  font-size: 1.6em;
  line-height: 1.45;
}

.landing-page .box-wrapper .description p {
  margin: 0;
}

.landing-page .box-wrapper .button-wrapper .single-button {
  display: inline-block;
  border-radius: 30px;
  text-transform: uppercase;
  color: #fff;
  background-color: #447c58;
  cursor: pointer;
  transition: 0.4s ease-in-out;
}

.landing-page .box-wrapper .button-wrapper .single-button:hover {
  background-color: #386648;
}

.landing-page .box-wrapper .button-wrapper .single-button .button-text {
  display: block;
  width: 100%;
  height: 100%;
}

.landing-page .box-wrapper .button-wrapper .login-button a {
  padding: 12px 40px;
  text-align: center;
  display: block;
  box-sizing: border-box;
}