.counter-pemira__page {
  height: 100vh;
}
.counter-pemira__page .counter-pemira__container {
  max-width: 1080px;
  margin: 0 auto 50px;
  padding: 0 10px 0;
}
.counter-pemira__page .counter-pemira__container .header {
  margin-bottom: 45px;
}
.counter-pemira__page .counter-pemira__container .header h1 {
  margin: 0 auto 10px;
}
.counter-pemira__page .counter-pemira__container .header p {
  margin: 0 auto 0;
  text-align: center;
}
.counter-pemira__page .counter-pemira__container .counter {
  transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: pointer;
  margin: 0 auto 50px;
  max-width: 700px;
  padding: 20px 40px 20px;
  border-radius: 10px;
  border: 1px solid white;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.15);
}

.counter-pemira__page .counter-pemira__container .counter:hover {
  background-color: rgba(197, 197, 197, 0.034);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.07);
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.counter-narrow {
  width: 550px;
}

.change-password-button-container {
  margin: 2px auto 0;
  text-align: right;
}

.change-password-button-container .change-password-button {
  color: rgb(243, 243, 243);
  border: 0;
  outline: none;
  background-color: #5773d6;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.4s ease-in-out;
  display: inline-block;
  text-align: center;
  width: 100%;
  height: 50px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

button:disabled,
button[disabled] {
  background-color: #cccccc !important;
  color: #666666 !important;
}

* {
  font-family: "Poppins", sans-serif;
}

.chart-pemira__page {
  text-align: center;
  padding: 10px 50px 50px;
}

canvas{
    margin: auto !important;
}

.c {
    color: rgba(87, 115, 214, 0.502);
}